import logo from 'assets/images/logo-ekz.png';

const Logo = () => {
    

    return (
         <img src={logo} alt="iTrack dashboard logo" width="100" />
    );
};

export default Logo;
