const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead
    basename: '',
    defaultPath: '/dashboard',
    fontFamily: `'Lato', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'theme1', // default, theme1, theme2, theme3, theme4, theme5, theme6
    i18n: 'nl', // 'en' - English, 
    rtlLayout: false,
    endpoint: {
        admin: 'https://api.kynda.one/api/v1/IMPTRCK',   // admin actions
        stats: 'https://api.kynda.one/api/v1/ECOREADER', // ecoreader stats
        auth: 'https://auth.kynda.one/api/v1/IMPTRCK',   // login and other auth relates calls
    },
    firebase: {
        apiKey: "AIzaSyCoulinK8FvNObQyS_Om9cJWGiI9vlIF0Q",
        authDomain: "itrckcld-dev.firebaseapp.com",
        databaseURL: "https://itrckcld-dev-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "itrckcld-dev",
        storageBucket: "itrckcld-dev.appspot.com",
        messagingSenderId: "263428349768",
        appId: "1:263428349768:web:fab4ade4ebd6a4bfdb1d7d"
    },
};

export default config;
